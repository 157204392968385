<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="submitSearch"></el-button>
          </el-input>
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
    </div>
    <div class="mv-30 mt-10 mb-30 el-tabs-scrollY">
      <el-tabs v-model="activeName" @tab-click="handleClick" tabPosition="left">
        <el-tab-pane v-for="(item,index) in tabPaneList" :key="index" :label="item" :name="item">
          <!-- 表格 -->
          <TableHeightFull v-if="item == activeName">
            <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%"
              :border="false" @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
              <template v-slot:img="scope">
                <img :src="scope.row.coverImg" alt="" class="tableImg">
              </template>
            </EleTable>
            <!-- 分页 -->
            <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableList" />
          </TableHeightFull>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="30vh" width="30%">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="键" prop="index" :rules="{required: true, message: '键不能为空', trigger: 'blur'}"
          v-if="'index' in ruleForm">
          <el-input type="number" v-model="ruleForm.index"></el-input>
        </el-form-item>
        <el-form-item label="值" prop="value" :rules="{required: true, message: '值不能为空', trigger: 'blur'}"
          v-if="'value' in ruleForm">
          <el-input type="text" v-model="ruleForm.value"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
export default {
  name: 'dictionaries',
  mixins: [indexMixin],
  components: {
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "CountDown": (resolve) => require(["@/components/CountDown"], resolve),
  },
  data () {
    return {
      activeName: 'box_type',
      tabPaneList: [],
      butLoading: false, //按钮
      tabLoading: false, //表单
      retrieveForm: {
        searchValue: '',
      },
      columns: [
        { label: '键', prop: 'index', align: 'left', 'show-overflow-tooltip': true },
        { label: '值', prop: 'value', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', width: '200',
          buttons: [
            { type: 'text', text: '修改', fn: this.editList },
            { type: 'text', text: '删除', fn: this.deleteList }
          ],
        },
      ],
      tableData: [],
      //弹框
      dialogFormVisible: false,
      dialogTitle: '',
      ruleForm: {
        index: '',
        type: '',
        value: '',
      },
    }
  },
  mounted () {
    this.tabPanes()
  },
  methods: {
    //搜索
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableList()
    },
    //获取所有分类
    tabPanes () {
      this.$http.get('config/findConfigType').then(({ data: result }) => {
        let list = result.data
        this.tabPaneList = list
        this.activeName = list[0]
        this.getTableList()
      })
    },
    //切换标签页
    handleClick (data) {
      console.log(data.name)
      this.pageInfo.pageNum = 1
      this.getTableList()
    },
    //获取表格数据
    getTableList () {
      this.tabLoading = true
      this.$http.get('/config/findConfig', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          type: this.activeName,
          value: this.retrieveForm.searchValue,
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      })
    },
    //删除
    deleteList (data) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/config/removeConfigById', { id: data.id }).then(({ data: result }) => {
          this.getTableList()
          this.$store.dispatch('menu/findConfigs') //刷新字典
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      }).catch(_ => { })
    },
    //新增列表
    addList () {
      this.dialogTitle = '新增'
      this.ruleForm = {
        type: this.activeName,
        index: this.tableData[this.tableData.length - 1].index + 1,
        value: ""
      }
      this.dialogFormVisible = true
    },
    //修改
    editList (row) {
      console.log(row)
      this.dialogTitle = '修改'
      this.ruleForm = {
        id: row.id,
        value: row.value
      }
      this.dialogFormVisible = true
    },
    //确认弹框
    affirmPopup () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.butLoading = true
          if (this.dialogTitle == '新增') {
            this.$http.post('/config/addConfig', this.ruleForm).then(({ data: result }) => {
              this.dialogFormVisible = false
              this.$message({
                message: '新增成功',
                type: 'success'
              })
              this.butLoading = false
              this.getTableList()
            }).catch(err => {
              this.butLoading = false
            })
          } else if (this.dialogTitle == '修改') {
            this.$http.post('/config/editConfigById', this.ruleForm).then(({ data: result }) => {
              this.dialogFormVisible = false
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.butLoading = false
              this.getTableList()
              this.$store.dispatch('menu/findConfigs') //刷新字典
            }).catch(err => {
              this.butLoading = false
            })
          }
        }
      })
    },
    //跳转详情
    headerRowClick (row) {
      console.log(row)
    },
  },
}
</script>

<style lang="less" scoped>
</style>